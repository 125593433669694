<template>
	<div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
                     <h4 class="limitador font-22 mb-3">{{ aula.tituloAula }}</h4>
                  </div>
                  <div class="w-max-content">
                     <i class="far fa-undo-alt font-20 cursor-pointer float-end" @click="fecharAula"></i>
                  </div>
               </div>

               <div class="row">
                  <div class="col-xl-6 col-xxl-8 mb-4 mb-xxl-0" id="video">
                     <div class="ratio ratio-16x9" v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                        <iframe class="rounded" :src="aula.link" title="Video player" ref="myIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" allowfullscreen></iframe>
                     </div>
                     <p class="mb-3" v-else><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ aula.descricao }}</p>
                  </div>
                  <div class="col-xl-6 col-xxl-4" id="aulas">
                     <h4 class="limitador font-20 mb-2">{{ bloco.titulo }}</h4>
                     <div class="card mb-1 cursor-pointer" :class="index % 2 == 0 ? 'invert' : ''" v-for="(aula, index) in bloco.aulas" :key="index">
                        <div class="card-body">
                           <div class="row align-items-center">
                              <div class="col" @click="abrirAula(aula)">
                                 <h6 class="limitador-2 font-16 weight-400 mb-0" :class="aula.idAula == aulaAtual ? 'color-theme' : ''">{{ aula.tituloAula }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <i class="far font-18 cursor-pointer" :class="aula.visualizado == true ? 'fa-check-circle text-success' : 'fa-times-circle'" @click="saveAulaMarcada(aula)"></i>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="String(aula.link).startsWith('https://www.youtube.com/embed/') || String(aula.link).startsWith('https://iframe.mediadelivery.net/embed/')">
         <div class="card mb-2">
            <div class="card-body">
               <h4 class="limitador font-22 mb-3">{{ $t('admAula.descricao') }}</h4>
               <p class="mb-0">{{ aula.descricao }}</p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'Aula',
	data: function() {
		return {
         pesquisa: {'resultado': []},
         bloco: [],
         aula: {},
         moduloAtual: null,
         blocoAtual: null,
         aulaAtual: null,
         ligado: false,
         screenWidth: window.innerWidth,
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   methods: {
      searchAllFromBloco : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFromBloco',
            params: {
               id: this.moduloAtual,
               idUsuario: this.dadosUsuario.id
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;
            this.loadBloco()

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      loadBloco : function () {
         this.bloco = this.pesquisa.resultado.find(bloco => bloco.id == this.blocoAtual);
         this.loadAula()
      },
      loadAula : function () {
         if (this.bloco && this.bloco.aulas) {
            this.aula = this.bloco.aulas.find(aula => aula.idAula == this.aulaAtual);
         }
      },
      abrirAula : function (aula) {
         if (this.aulaAtual != aula.idAula) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.aulaAtual = aula.idAula
            this.loadAula()
            this.$router.push('/admAula/' + this.moduloAtual + '/' + this.blocoAtual + '/' + aula.idAula);
            this.$store.dispatch('alternarTelaCarregamento', false)
         }
      },
      fecharAula : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admClassroom/' + this.moduloAtual);
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      saveAulaMarcada : function (aula) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/saveAulaMarcada',
            params: {
               idAula: aula.idAula,
               idUsuario: this.dadosUsuario.id
            }
         }).then(() => {
            this.setAulaMarcada(aula)
            this.searchAllFromBloco()

            setTimeout(() => {
               this.loadBloco()
               this.loadAula()
            }, 100);
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarPacote').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      setAulaMarcada : function (aula) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicosAdmin/setAulaMarcada',
            params: {
               idAula: aula.idAula,
               idUsuario: this.dadosUsuario.id,
               idModulo: this.moduloAtual
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            $('#modalEditarPacote').modal('hide')
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
   },
   created() {
      this.moduloAtual = this.$route.params.idModulo;
      this.blocoAtual = this.$route.params.idBloco;
      this.aulaAtual = this.$route.params.idAula;
   },
   mounted() {
      this.searchAllFromBloco()

      setTimeout(() => $("#aulas").height($("#video").height() +'px'), 500);
      $(window).resize(() => $("#aulas").height($("#video").height() +'px'));
   },
}

</script>

<style scoped>

#aulas {
   min-height: 500px;
   overflow-y: auto;
}

#video {
   height: max-content;
}

</style>